import request from '@/utils/request'

export function setPageConfig (data) {
  return request({
    url: '/activity/setPageConfig',
    data
  })
}

export function getPageConfig (data) {
  return request({
    url: '/activity/getPageConfig',
    data
  })
}

export function getVrAuditVersion (data) {
  return request({
    url: '/activity/getVrAuditVersion',
    data
  })
}

export function setVrAuditVersion (data) {
  return request({
    url: '/activity/setVrAuditVersion',
    data
  })
}

export function updateVrAuditVersion (data) {
  return request({
    url: '/activity/updateVrAuditVersion',
    data
  })
}
