<template>
  <Layout>
    <h2 style="margin-left: 46px;">
      节日活动配置
    </h2>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      size="small"
      label-width="200px"
    >
      <el-form-item
        label="节日活动开关"
        prop="status"
      >
        <el-switch
          v-model="formData.status"
          style="display: block"
          :active-value="1"
          :inactive-value="2"
          active-text="开"
          inactive-text="关"
        />
      </el-form-item>
      <el-form-item
        label="开屏页是否展示活动页面"
        prop="open_screen_img"
      >
        <UploadImage2
          preview-style="width: 140px; height: 280px;"
          :show-close="true"
          :url.sync="formData.open_screen_img"
        />
      </el-form-item>
      <el-form-item
        label="登录页是否显示活动页面"
        prop="login_screen_img"
      >
        <UploadImage2
          preview-style="width: 140px; height: 280px;"
          :show-close="true"
          :url.sync="formData.login_screen_img"
        />
      </el-form-item>
      <el-form-item
        label="订阅页宣传标语开关"
        prop="slogan"
      >
        <el-input
          v-model="formData.slogan"
          style="width: 420px;"
          clearable
          placeholder="单行输入"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="loading"
          type="primary"
          style="width: 420px;"
          @click="handleSave"
        >
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </Layout>
</template>

<script>
import {
  getPageConfig,
  setPageConfig
} from '@/api/activity.js'

export default {
  name: 'Christmas',
  data () {
    return {
      formData: {},
      rules: {},
      loading: false
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      getPageConfig()
        .then(res => {
          if (res.code === 200) {
            this.formData = res.data
          }
        })
    },
    handleSave () {
      if (this.loading) return
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          setPageConfig(this.formData)
            .then(res => {
              if (res.code === 200) {
                this.$message.success('更新成功')
              }
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="less">

</style>
